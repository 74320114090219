import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';

import { breakpoints } from '../utils/style';

import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEO';
import Breadcrumbs from '../components/shared/Breadcrumbs';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 1200px;
  margin: 30px auto;

  @media (max-width: ${breakpoints.tl}) {
    padding: 0 16px;
  }
`;

const List = styled('ul')`
  list-style: none;
  margin-top: 30px;
  padding: 0;
  li {
    padding: 7px 15px;
    margin-top: 8px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
  }
  li:nth-of-type(2n) {
    background-color: transparent;
  }
  a {
    opacity: 0.8;
  }
  a:hover,
  a:active {
    text-decoration: none;
    opacity: 1;
  }
`;

const HtmlSitemap = ({ data }) => {
  const title = `${process.env.SITE_NAME_SHORT} HTML Sitemap`;
  const description = `HTML sitemap for ${process.env.SITE_NAME_SHORT}`;

  const Posts = data.allWordpressPost.edges?.map((edge) => (
    <li key={edge.node.id} className="post">
      <Link to={`${edge.node.slug}/`} dangerouslySetInnerHTML={{ __html: edge.node.title }} />
    </li>
  ));
  const Pages = data.allWordpressPage.edges?.map((edge) => (
    <li key={edge.node.id} className="page">
      {edge.node.slug === 'home' ? (
        <Link to="/" dangerouslySetInnerHTML={{ __html: edge.node.title }} />
      ) : (
        <Link
          to={edge.node.wordpress_parent > 0 ? `${edge.node.path}/` : `${edge.node.slug}/`}
          dangerouslySetInnerHTML={{ __html: edge.node.title }}
        />
      )}
    </li>
  ));
  const Categories = data.allWordpressCategory.edges?.map((edge) => (
    <li key={edge.node.id} className="category">
      <Link to={`${edge.node.slug}/`} dangerouslySetInnerHTML={{ __html: edge.node.name }} />
    </li>
  ));
  return (
    <Layout>
      <SEO title={title} description={description} />
      <Wrapper>
        <Breadcrumbs page_title={'Sitemap'} breadcrumbs={[{ title: 'Home', slug: '' }]} />

        <h1>HTML sitemap</h1>
        <List>
          {Pages}
          {Categories}
          {Posts}
        </List>
      </Wrapper>
    </Layout>
  );
};

export default HtmlSitemap;

export const pageQuery = graphql`
  query all {
    allWordpressCategory {
      edges {
        node {
          id
          name
          slug
          path
          link
        }
      }
    }
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          title
          id
          slug
        }
      }
    }
    allWordpressPage(sort: { fields: [date] }) {
      edges {
        node {
          title
          id
          slug
          path
          wordpress_parent
        }
      }
    }
  }
`;
